import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { client } from '../client';
import { Helmet } from 'react-helmet';
import { useTheme } from '../Context/Theme'
import ShowBlog from '../Pages/Blog/Article'
import '../Styles/ThoughtStyles/ThoughtStyles.css'
import { format } from "date-fns"


export default function AllThoughts() {
  const [posts, setPosts] = useState([])

  
  useEffect(() => {
    client.fetch(`*[_type == "post"] {
      title,
      slug,
      body,
      categories[
        {
          _key,
          _ref,
          title,
        }],
      publishedAt,
      mainImage {
        asset -> {
          _id,
          url
        },
        alt,
      }
    } | order(publishedAt desc)`).then((data) => setPosts(data)).catch(console.error)
  }, [])

  
  return <>
    <div className='thts-pg'>
    <Helmet>
     <title>Thoughts | Raymond Mbam</title>
     <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
   </Helmet>


   <div className='thts-frame'>
   <div className='thts-header'>
      <h2>My thoughts</h2>
      <p className='thts-title'>Articles, project deep-dives and everything in between.</p>
    </div>


  {posts && posts.map((post, index) => (
    <div >
    <Link className='thts-link' to={'/thoughts/' + post.slug.current} key={post.slug.current}>
      <div className='thts-card'>

       
        

        <div className="txtr">
          <p className='thts-title'>{post.title}</p>
          <p className='thts-pre'>{`${post.body[0].children[0].text.substring(0,80)}...`}</p>
          <p className='thts-date'>{format(new Date(post.publishedAt), "MMMM yyyy")}</p>
        </div>
        
      </div>
    </Link>
    </div>
  ))}
    </div>
    </div>
  </>
}


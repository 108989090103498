import React from 'react'
import '../../Styles/CaseStudy/caseStudies.css'
import Footer from '../../Resources/Footer'
import { useTheme } from '../../Context/Theme'
import OldFlow from '../../Assets/videos/old-flow.mp4'



// Images
import maildripCover from '../../Assets/images/maildrip-cover.png'
import maildripContent from '../../Assets/images/maildrip-content.png'
import maildripOverview from '../../Assets/images/maildrip-overview.png'
import maildripBusiness from '../../Assets/images/maildrip-business-goal.png'
import maildripDiscovery from '../../Assets/images/maildrip-discover.png'
import maildripResearch from '../../Assets/images/maildrip-research.png'
import maildripAnalysis from '../../Assets/images/maildrip-analysis.png'
import maildripDefine from '../../Assets/images/maildrip-define.png'
import maildripChallenge from '../../Assets/images/maildrip-challenges.png'
import maildripHMW from '../../Assets/images/maildrip-hmw.png'
import maildripDevelop from '../../Assets/images/maildrip-develop.png'
import maildripLofi from '../../Assets/images/maildrip-lofi.png'
import maildripDS from '../../Assets/images/maildrip-design-system.png'
import maildripHifi from '../../Assets/images/maildrip-hifi.png'
import maildripDeliver from '../../Assets/images/maildrip-deliver.png'
import maildripResults from '../../Assets/images/maildrip-results.png'
import maildripReflection from '../../Assets/images/maildrip-reflection.png'
import maildripTakeaway from '../../Assets/images/maildrip-takeaway.png'

import home from '../../Assets/images/cl/Home.png'
import aboutMe from '../../Assets/images/cl/about-me-1.png'
import aboutMe2 from '../../Assets/images/cl/about-me-2.png'
import designPhilosophy from '../../Assets/images/cl/design-philosophy.png'
import maildripThanks2 from '../../Assets/images/cl/maildrip-thanks-2.png'

const CoverLetter = () => {
  const { dark } = useTheme()
    const id ="maildrip"
    

  return (

    <div className={`cs-bd ${dark && "darkbg"}`}>

    

        <section className='cardify-top'>
         <img src={home} alt="home-cover" className='lofi'/>
            </section>

        {/*<section className='cardify'>
              <video controls className='lofi'>
            <source src={OldFlow} type="video/mp4"  />

            <source src={OldFlow} type="video/mp4" />

            or
            <a href={OldFlow}>MP4</a>
            video.
              </video>
         </section>*/}
         
         <section className='cardify'>
         <img src={aboutMe} alt="about-me" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={aboutMe2} alt="about-me" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={designPhilosophy} alt="design-philosophy" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={maildripCover} alt="maildrip-cover" className='lofi'/>
         </section>




         <section className='cardify'>
          <img src={maildripContent} alt="maildripContent" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={maildripOverview} alt="maildripOverview" className='lofi'/>
        </section>

         <section className='cardify'>
         <img src={maildripBusiness} alt="maildripBusiness" className='lofi'/>
        </section>

        <section className='cardify'>
        <img src={maildripDiscovery} alt="maildripDiscovery" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={maildripResearch} alt="maildripResearch" className='lofi'/>
       </section> 

       <section className='cardify'>
         <img src={maildripAnalysis} alt="maildripAnalysis" className='lofi'/>
            </section>

        <section className='cardify'>
          <img src={maildripDefine} alt="maildripDefine" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={maildripChallenge} alt="maildripChallenge" className='lofi'/>
        </section>

         <section className='cardify'>
         <img src={maildripHMW} alt="maildripHMW" className='lofi'/>
        </section>

        <section className='cardify'>
        <img src={maildripDevelop} alt="maildripDevelop" className='lofi'/>
       </section>

       <section className='cardify'>
        <img src={maildripLofi} alt="maildripLofi" className='lofi'/>
       </section> 

       <section className='cardify'>
       <img src={maildripDS} alt="maildripDS" className='lofi'/>
      </section>

       <section className='cardify'>
         <img src={maildripHifi} alt="maildripHifi" className='lofi'/>
            </section>

        <section className='cardify'>
        <img src={maildripDeliver} alt="maildripDeliver" className='lofi'/>
         </section> 

        <section className='cardify'>
          <img src={maildripResults} alt="maildripResults" className='lofi'/>
         </section>

         <section className='cardify'>
         <img src={maildripReflection} alt="maildripReflection" className='lofi'/>
        </section>

         <section className='cardify'>
         <img src={maildripTakeaway} alt="maildripTakeaway" className='lofi'/>
        </section>

        <section className='cardify'>
        <img src={maildripThanks2} alt="maildripThanks" className='lofi'/>
       </section> 
       
       





       <Footer />
      </div>
  )
}

export default CoverLetter

import React from 'react'
import { useTheme } from '../../Context/Theme'
import '../../Styles/ContentBlock.css'

const Contentblock = ({BlockContent, Column, HeaderColor, textColor}) => {

  const { dark } = useTheme()

    // this is how the blockContent prop data should be 
    // BlockContent = [
    //     {
    //         icons: "",
    //         smallHead:"",
    //         smallBody:"",
    //     }
    // ]

    // if you need to specify flex column to arange the elements on them themselves call the column property like this 
    // Column ="column"
    // for color d the same specify the colors 

    // I've not tessted it yet but when you d let me know if anything is wrong

    const Parentstyle = {
       display:"flex",
       flexDirection:Column,
       gap:"20px",
       width:"100%",
      };

      const childStyle= {
        display:'flex',
        flexDirection:"row",
        gap:"20px",
      }

      const image = {
        height:"50px",
        width:"50px",
      }

      const h4txt = {
        color:HeaderColor,
      }

      const h4Head = {
        fontSize:"1.2rem",
        marginBottom:"10px",
        color: "#37352F",
        fontWeight:"600",
      }

      const PTXT = {
        color:textColor
      }

      const iconStyle = {
        width:"100%",
        height:"100%",
        
      }


  return (
    <div className='contentBlk' style={Parentstyle}>
      {BlockContent.map((item, index) => (
        <div  key = {index} style={childStyle}>
           {item.icons}
            <div 
            >
                <h4 style={h4Head} className={dark && "darkHead"}>{item.smallHead}</h4>
                <p className={`pr-aag ${dark && "darkText"}`}>{item.smallBody}</p>
            </div>
        </div>
      ))}
    </div>
  )
}

export default Contentblock

import React from 'react'
import CaseDefault from "../../Components/ProjectComponents/CaseDefault"
import CaseHead from "../../Components/ProjectComponents/CaseHead"
import SpecialHeader from '../../Components/ProjectComponents/SpecialHeader'
import { SelectData } from '../../Resources/SelectData'
import '../../Styles/CaseStudy/caseStudies.css'
import SubHeads from '../../Components/ProjectComponents/SubHeads'
import SubBody from '../../Components/ProjectComponents/SubBody'
import SingleImage from '../../Components/ProjectComponents/SingleImage'
import Contentblock from '../../Components/ProjectComponents/Contentblock'

// Images
import mdHomepage from '../../Assets/images/md-homepage.gif'
import maildripCampaign from '../../Assets/images/pickuppalTest1.png'
import mdpersona from '../../Assets/images/md-persona.png'
import mduserflow from '../../Assets/images/md-userflow.png'
import mdlofi from '../../Assets/images/md-lofi.png'
import mdhifi from '../../Assets/images/md-hifi.png'
import { useTheme } from '../../Context/Theme'
import { RiTeamFill } from "react-icons/ri";
import { FaHeart } from "react-icons/fa";
import { PiUsersFourFill } from "react-icons/pi";

const Maildrip = () => {

    const { dark } = useTheme()
    const id ="maildrip"
    const prod = SelectData.find((select) => id === select.id)

    const {image, catchPhrase} = prod

    const overview = [
        "<p className=''>In my role as a Product Designer at Charisol, I took on the challenge of revamping MailDrip, an email automation tool initially designed to handle only an aspect of client operation, but expanded for wider market use. Faced with usability challenges in the initial release, I delved into thorough user research, including surveys and usability tests, creating two user personas.</p>",
        "<br/> The design goals for the new MailDrip were clear — enhance the user experience by <b>ensuring responsiveness across devices</b>, prioritize <b>simplicity</b> provide <b> effective analytics and data visualization</b>, and align with brand identity. The redesign, featuring low-fidelity wireframes and a high-fidelity prototype, garnered positive feedback, increasing conversion rates and user sign-ups.",
        "<br/> Ther result was a seamless and intuitive user experience, leading to increased conversion rates and user satisfaction.",
      ]

    const BlockContent = [
        {
         smallHead:"All teams should be involved.",
         smallBody:"In the spirit of seamless collaboration, it's important to involve cross-functional partners from the start, ensuring that grooming meetings become the cornerstone of a well-rounded product development process.",
        }]

    const BlockContent2 = [
          {
           smallHead:"User research as a catalyst for success.",
           smallBody:"This MailDrip UX case study underscores the pivotal role of user research and testing in the design process. By understanding user needs and goals, the redesign effectively communicated the product's value, resulting in a positive user experience, increased conversions, and heightened user satisfaction.",
          }]
          

    const BlockContent3 = [
            {
             smallHead:"Brand Harmony",
              smallBody:"Reflect MailDrip's brand identity in the design.",
            }]

    
    const BlockContent4 = [
              {
              smallHead:"Responsive Design",
            smallBody:"Ensure the application works seamlessly across various devices.",
            }]

      const BlockContent5 = [
      {
      smallHead:"Simplicity Reigns", 
          smallBody:"Focus on an uncluttered design, allowing users to concentrate on key tasks.",
        }]

        const BlockContent6 = [
          {
          smallHead:"Data Clarity",
              smallBody:"Facilitate easy access and interpretation of analytics and data visualizations.",
            }]

      
                

  return (
    <div className={`cs-body ${dark && "darkbg"}`}>
        <CaseDefault 
         Name="MailDrip"
         followUp={catchPhrase}
         biImage={image}
         role="Lead Product Designer — User Research, Prototyping, Visual Design, Usability Testing"
         Team={['Kenneth C., PM',
         '<br/>Olayinka O., SWE',
         '<br/>Femi S., SWE',
         '<br/>Zaynab O., UXR',
         '<br/>Victor C., JPD',
        ]}
         Timeline="Launched Redesign in July 2023"
         overview={overview}
         />

         <CaseHead
        caseHeader={"CONTEXT"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Elevating MailDrip to New Heights."} />

                    <SubBody
                    subBody={[
                      "<p className='pr-aag'>The email marketing industry is highly competitive, and MailDrip, originally designed for a specific client's needs, faced challenges upon its public release. The goal was to address usability issues and add value to businesses seeking an efficient email automation solution.</p>"
                    ]} />
        </div>


        <CaseHead
        caseHeader={"THE PROBLEM"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={'A Foggy User Journey and Unclear Value Proposition.'} />

                    <SubBody
                    subBody={[
                      "<p className='pr-aag'>The initial design of the MailDrip website application failed to communicate its value effectively, leading to user frustration and usability challenges. Tinkering with the design without research proved futile, highlighting the importance of a user-centric approach. </p>"
                    ]} />
        </div>

      
        <CaseHead
        caseHeader={"CHALLENGE"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Craft a Redesign that Prioritizes a Seamless User Experience."} />

                    <div className='one-long-columns'>
                    <Contentblock
                    BlockContent = {BlockContent4} />

                    <Contentblock
                    BlockContent = {BlockContent5} />

                    <Contentblock
                    BlockContent = {BlockContent6} />

                    <Contentblock
                    BlockContent = {BlockContent3} />

                    </div>
        </div>

        <CaseHead
        caseHeader={"REDESIGN PROCESS"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Transforming Challenges into Opportunities."} />

                    <SubBody
                    subBody={[
                      "<p className='pr-aag'>The redesign journey kicked off with comprehensive user research, including surveys and usability tests to gather feedback on the previous design and to understand the user goals and pain points (which helped me create tasks for usability tests) when using email automation tools.</p>", 
                      "<p className='pr-aag'> Based on the findings, I created two user personas — a <b>small business owner</b> and a <b>marketing professional</b>.</p>"
                    ]} />
        </div>
        <SpecialHeader 
        highHead={"User Persona"} />
        <SingleImage single={mdpersona} />
        <SpecialHeader 
        highHead={"Information Architecture"} />
        <SingleImage single={mduserflow} />
        <SpecialHeader 
        highHead={"Low-Fidelity Prototype"} />
        <SingleImage single={mdlofi} />
        <SpecialHeader 
        highHead={"High-Fidelity Prototype"} />
        <SingleImage single={mdhifi} />

        <CaseHead
        caseHeader={"RESULT"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"User Satisfaction and Increased Conversions."} />

                    <SubBody
                    subBody={[
                      "<p className='pr-aag'>The redesigned MailDrip web application received acclaim from users for its clarity, intuitiveness, straightforward sign-up process, and efficient campaign creation and tracking. Conversion rates soared, and more users signed up for the tool.</p>"
                    ]} />
        </div>

        <CaseHead
        caseHeader={"RETROSPECTIVE"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Project Takeaways"} />

                    <div className='one-long-columns'>
                    <Contentblock
                    BlockContent = {BlockContent} />

                    <Contentblock
                    BlockContent = {BlockContent2} />

                    

                    </div>
        </div>

        <div className='two-columns'>
                    <SubBody
                    subBody={[
                      "<h4>Appreciation</h4>",

                    "<p className='pr-aag'> Charisol has an amazing team of strong technical and business talents that make everyday work impactful, and fun.</p>",
                    
                    "<p className='pr-aag'> Visit app.maildrip.io to see the live product.</p>",
                  ]} />

                    <SubHeads
                    subCases={[""]} />

                    
        </div>

       

        
      
    </div>
  )
}

export default Maildrip

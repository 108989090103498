import React from 'react'
import "../../Styles/ProjectCases/case01.css"
import { useTheme } from '../../Context/Theme'

const SubHeads = ({subCases}) => {
  
  const { dark } = useTheme()
  return (
    <div className="sub-head"> 
        <h4 className={dark && "darkHead"}>{subCases}</h4>
    </div>
  )
}

export default SubHeads

import React from 'react'
import { FaStarOfLife } from "react-icons/fa6";
import "../../Styles/ProjectCases/caseHead.css"
import { IconContext } from 'react-icons';
import star from "../../Assets/images/star.svg"
import { useTheme } from '../../Context/Theme';


const CaseHead = ({caseHeader}) => {
  const { dark } = useTheme()
  return (
    <div  className={`caseheads ${dark && "darkHead"}`}>
    <img src={star} alt="star" className='star'/>
          <p className={dark && "darkHead"}>{caseHeader}</p>
      
    </div>
  )
}

export default CaseHead

import React, { useState, useEffect } from 'react';
import './Navbar.css'; // Import the CSS file for styling
import mainman from '../Assets/images/mainman.svg';
import { GoArrowUpRight } from "react-icons/go";
import { useTheme } from '../Context/Theme';
import { IoSunny, IoMoon } from "react-icons/io5";

function Navbar() {
  const [showNavbar, setShowNavbar] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(window.scrollY);
  const [isScrolled, setIsScrolled] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false); // State for hamburger menu
  const { dark, toogleMode, setDark } = useTheme(); 

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > lastScrollY) {
        setShowNavbar(false);
      } else {
        setShowNavbar(true);
      }

      setLastScrollY(window.scrollY);

      // Check if user has scrolled more than a specific amount to add the white line
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollY]);

  // Function to toggle mobile menu
  const handleMenuToggle = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className={`navbar 
      ${showNavbar ? 'active' : 'hidden'} 
      ${isScrolled ? 'scrolled' : ''}
      ${dark ? "darkbg" : ""}
      `}>
      <div className="me-logo">
        <img src={mainman} alt="him" />
        Raymond Mbam
      </div>

      {/* Hamburger Menu Icon */}
      <div className="hamburger" onClick={handleMenuToggle}>
        <span></span>
        <span></span>
        <span></span>
      </div>

      {/* Navbar Menu */}
      <ul className={`navbar-menu ${menuOpen ? 'mobile active' : 'mobile'} ${dark ? "darkbg" : ""}`}>
        <li><a className={`navbar-item ${dark ? 'lightText' : ''}`} href="/">Home</a></li>
        <li><a className={`navbar-item ${dark ? 'lightText' : ''}`} href="/projects">Work</a></li>
        <li><a className={`navbar-item ${dark ? 'lightText' : ''}`} href="/about">About Me</a></li>
        <li><a className={`navbar-item ${dark ? 'lightText' : ''}`} href="https://www.linkedin.com/in/raymondmbam/">LinkedIn <GoArrowUpRight /></a></li>
        <li><a className={`navbar-item ${dark ? 'lightText' : ''}`} href="https://drive.google.com/file/d/12NvsMHBeB5cDCXO2pQAln32usKtbBca6/view?usp=sharing">Resume <GoArrowUpRight /></a></li>
        
      </ul>
    </nav>
  );
}

export default Navbar;

// import React, { useState, useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import { Helmet } from 'react-helmet';
// import { useTheme } from '../../Context/Theme'
// import useFetch from './useFetch';
// import '../../Styles/ThoughtStyles/ThoughtStyles.css'
// import HTMLReactParser from 'html-react-parser';


// function Blog() {
//   const { data, error, loading } = useFetch("all");

//   if (loading) {
//     return <h1>Loading...</h1>;
//   }

//   if (error) {
//     return <h3 style={{ textAlign: "center" }}>Something went wrong. Check your internet connection...</h3>;
//   }

//   return (
//     <div className='thts-pg'>
//       <Helmet>
//         <title>Blog | Raymond Mbam</title>
//         <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
//       </Helmet>

//       <div className='thts-frame'>
//         <div className='thts-header'>
//           <h2>My thoughts</h2>
//           <p className='thts-title'>Articles, project deep-dives and everything in between.</p>
//         </div>

//         <div>
//           {data
//             .sort((a, b) => b.id - a.id)
//             .map((element) => (
//               <Link
//                 className="thts-card"
//                 key={element.id}
//                 to={`/blog/${element.slug}`} // Ensure correct path
//               >
//                 <div className='txtr'>
//                   <p className='thts-title'>{element.title}</p>
//                   <p className='thts-pre'>
//                     {HTMLReactParser(element.content.substring(0, 80))}...
//                   </p>
//                   <div className="thts-date">Published on {element.date}</div>
//                 </div>
//               </Link>
//             ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Blog;


import React, { Component } from "react";
import Axios from "axios";
import ToText from "./ToText";  // Import the utility for text transformation
import moment from "moment";
import { Helmet } from 'react-helmet';
import '../../Styles/ThoughtStyles/ThoughtStyles.css'
import { Link } from "react-router-dom";  // Ensure you import Link for routing

export class Blog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      profile: {
        ptitle: "",
        name: "",
        avtar: "",
        profileurl: "",
      },
      item: [],
      isloading: true,
      error: null
    };
  }

  mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@raymondmbam";

  componentDidMount() {
    Axios.get(this.mediumURL)
      .then((data) => {
        const avatar = data.data.feed.image;
        const profileLink = data.data.feed.link;
        const res = data.data.items; // This is an array with the content.
        const posts = res.filter(item => item.categories.length > 0);

        const title = data.data.feed.title;

        this.setState({
          profile: {
            ptitle: title,
            profileurl: profileLink,
            avtar: avatar,
          },
          item: posts,
          isloading: false
        });
      })
      .catch((e) => {
        this.setState({ error: e.toJSON(), isloading: false });
        console.log(e);
      });
  }

  render() {
    const { profile, item, isloading, error } = this.state;

    let postContent;

    if (isloading) {
      return <h1>Loading...</h1>;
    }

    if (error) {
      const errorTitle = error.code ? error.code : error.name;
      const errorMsg = error.message;
      postContent = (
        <>
          <h2 className="red center1">{errorTitle}</h2>
          <p className="errorMessage center1">{errorMsg}</p>
        </>
      );
    } else {
      postContent = item.map((post, index) => (
        <div key={index}>
          <div className='thts-link'>
            <Link to={post.title} className="thts-card">
              <div className="txtr">
                <p className='thts-title'>{post.title}</p>
                <p className='thts-pre'>{`${ToText(post.description.substring(0, 300))}...`}</p>
                <p className='thts-date'>{"Published on " + moment(post.pubDate).format("MMM DD")}</p>
              </div>
            </Link>
          </div>
        </div>
      ));
    }

    return (
      <div className="thts-pg">
        <Helmet>
          <title>Thoughts | Raymond Mbam</title>
          <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
        </Helmet>
        <div className='thts-frame'>
          <div className='thts-header'>
            <h2>My thoughts</h2>
            <p className='thts-title'>Articles, project deep-dives and everything in between.</p>
          </div>
          {postContent}
        </div>
      </div>
    );
  }
}

export default Blog;

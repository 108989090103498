import React, {useState, createContext, useContext} from "react";

//context instance
const ThemeContext = createContext();

//context provider Hook 
export const useTheme = () =>{
    return useContext(ThemeContext)
}


export const ThemeProvider = ({children}) =>{
    //State to handle the modes
    const [dark, setDark] = useState(false)

    //function to update the states
    const toogleMode = () =>{
        setDark(!dark)
    }
    return(
        <ThemeContext.Provider value ={{dark, setDark, toogleMode}}>
            {children}
        </ThemeContext.Provider>
    )
}


import alps from "../Assets/images/alps-header.png"
import pickuppal from "../Assets/images/pickuppalHeader.png"
import maildripCover from '../Assets/images/maildrip-cover.png'
import grapes from "../Assets/images/grapes-cover.png"
import spaceHeader from "../Assets/images/space-header.png"
import buffHeader from "../Assets/images/buff-header.png"





export const SelectData=[

    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"maildrip",
        maintitle:"Maildrip",
        projName:"MailDrip",
        catchPhrase:"From Inbox to Impact: Empowering Small Businesses and Creators",
        genre:"PRODUCT DESIGNER, 2021 — 2023.",
        littleBrief:"",
        brief:"Redesigned MailDrip's user experience, aligning it with brand design, and increasing responsiveness. Led usability tests to identify pain points and enhance user flow, resulting in increased conversion rates.",
        image: maildripCover,
        Link:"/maildrip"

    },

    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"alps",
        maintitle:"Alps",
        projName:"Alps",
        catchPhrase:"Empowering product teams to craft helpful guides",
        genre:"FOUNDING DESIGNER, Ongoing.",
        littleBrief:"",
        brief:"Led design and product for a SAAS platform that empowers startups in Africa to craft helpful guides.",
        image: alps,
        Link:"/alps"

    },

    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"grapes",
        maintitle:"Grapes",
        projName:"Grapes",
        genre:"PRODUCT DESIGNER, 2023.",
        catchPhrase:"Simplifying Identity Verification and Savings for Non-Immigrant Students",
        littleBrief:"",
        brief:"Designed a mobile-based application for non-immigrant students aimed at lowering the barrier to entry into the financial system in the United States",
        image: grapes,
        Link:"/grapes"

    },


    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"pickuppal",
        maintitle:"PickUpPal",
        projName:"PickUpPal ",
        catchPhrase:"Connecting Players and Facilities for Seamless Ad Hoc Team Sports",
        genre:"PRODUCT DESIGNER, 2023.",
        littleBrief:"",
        brief:"Designed a local game discovery app to address the challenge faced by young adults in their 20s who struggle to find local ad hoc team sports games. PickUpPal aimed to enhance physical activity and social interaction.",
        image: pickuppal,
        Link :"/pickuppal"
    },



    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"buff",
        maintitle:"Buff",
        projName:"buff",
        catchPhrase:"Crafting a Seamless Experience at the George Washington Library with a Multi-Modal Virtual Assistant",
        genre:"CONVERSATION DESIGNER, 2023.",
        littleBrief:"",
        brief:"Designed a friendly multi-modal virtual assistant at the George Washington Library to help the GW community perform library-related tasks like accessing library materials, and answering general questions about the library.",
        image: buffHeader,
        Link:"https://raymondmbam.medium.com/buff-virtual-assistant-design-for-george-washington-university-library-1de733fc1e41"

    },

    {
        // the constant section that would be passed into the caseDefaultcomponent
        id:"space",
        maintitle:"Space",
        projName:"Space",
        catchPhrase:"Streamlining Success and Boosting Productivity for Freelancers, Project and Operations Manager",
        genre:"PRODUCT DESIGNER, UX RESEARCHER, 2023.",
        littleBrief:"",
        brief:"Designed a web-based project management tool to help teams and clients work together efficiently and effectively.",
        image: spaceHeader,
        Link:"https://raymondmbam.medium.com/ux-case-study-space-project-management-web-application-b42f79b72c2c"

    },

    


]


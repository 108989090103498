import React from 'react'
import logo from "../Assets/logos/ray.svg"
import "../Styles/footer.css"
//icon for the contacts
import { GoArrowUpRight } from "react-icons/go";
import { Link } from 'react-router-dom'
import { IconContext } from 'react-icons';
import { useTheme } from '../Context/Theme';


const Footer = () => {

    const {dark} = useTheme() 
    return (
    <div className={`footer ${dark && "darkbg"}`}>
       
        

        <div className='owner'>
             <p className={`copyright ${dark && "darkText"}`}>
                &copy; 2024 Raymond Mbam. All Rights Reserved.
             </p>

             {/**<p className={`dn-t ${dark && "darkText"}`}>
             <span className= {`light ${dark && "darkText"}`}>Made with love, Mango and Spaghetti</span>
             <span>Last updated by Raymond on June 02, 2024</span>
             </p>**/}
        </div>

       
    </div>
  )
}

export default Footer


// <div className='foot-info'>
//             <div>
//                 <img src ={logo}/>
//             </div>

//             {/**footer Navigation */}
//             <IconContext.Provider value ={{size:"20px"}}>
//             <div className='footLinks'>
//                 <div className='sect'>
//                     <p className={dark && "darkText"}>MAIN</p>
//                     <ul className='mainlinks'>
//                         <li>
//                             <Link to="/" className={dark && "darkText"}>Projects</Link>
//                         </li>
//                         <li>
//                             <Link to="/" className= {dark && "darkText"}>info</Link>
//                         </li>
//                     </ul>

//                 </div>
                
//                 <div className='sect'>
//                     <p className={dark && "darkText"}>CONTACT</p>
//                     {/**THE CONTACT LINKS */}
//                     <ul className='ct-links'>
//                     <li>
//                         <Link to="/" className={dark && "darkText"}>
//                             LinkedIn
//                             <GoArrowUpRight />
//                         </Link>
//                     </li>

//                     <li>
//                         <Link to="" className={dark && "darkText"}>
//                             Resume
//                             <GoArrowUpRight />
//                         </Link>
//                     </li>
//                 </ul>
                
//                 </div>
               
//             </div>
//             </IconContext.Provider>
//     </div>
 import React from 'react'
import "../../Styles/HomeStyles/selected.css"
import rotate from '../../Assets/logos/rotate.svg'
import Button from '../../Resources/Button'
import { SelectData } from '../../Resources/SelectData'
import ProjectCard from '../../Resources/ProjectCard'
import { useTheme } from '../../Context/Theme'



  const Selected = () => {

    const {dark} = useTheme()
    return (
      <div className={`selected ${dark && "darkbg"}`}>
        <div className='sel-head'>
            <div className='rg-ray'>
                 {/*<img src={rotate} alt="rotate" className='sunray'/>*/}
                <h1>Featured Work.</h1>
            </div>

    {/*<Button name="See all projects" white/>*/}
        </div>

        <div  className='sel-card' >
            {SelectData.slice(0,4).map((item, index) => {
                return(
                    <div className='sel-card' key={index}>
                      <ProjectCard value={item}/>
                    </div>
                )
            })}
        </div>
      </div>
    )
  }
  
  export default Selected
    
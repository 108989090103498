import React from 'react';
import './Hero.css'; // Import the CSS file for styling

function HeroSection() {
    return (
        <section className="value-section">
         
          <div className="core-values">
            <div className="core-values-header">
              <h3>Core values</h3>
              <h3>Expressed in everything</h3>
            </div>
            <div className="core-values-content">
              <div className="core-value">
                <h4>Teamwork</h4>
                <p>I craft emotionally resonant designs, creating genuine user connections that foster loyalty and deeper interactions.</p>
              </div>
              <div className="core-value">
                <h4>Playfulness</h4>
                <p>I simplify designs to make intuitive, user-friendly interfaces that enhance satisfaction and efficiency.</p>
              </div>
              <div className="core-value">
                <h4>Learning</h4>
                <p>I craft emotionally resonant designs, creating genuine user connections that foster loyalty and deeper interactions.</p>
              </div>
              <div className="core-value">
                <h4>Integrity</h4>
                <p>I simplify designs to make intuitive, user-friendly interfaces that enhance satisfaction and efficiency.</p>
              </div>
            </div>
          </div>
        </section>
      );
}

export default HeroSection;

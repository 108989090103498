import React from 'react'
import { Link } from 'react-router-dom'
import { GoArrowUpRight } from "react-icons/go";
import { IconContext } from 'react-icons'
import './ProjectCard.css'
import { useTheme } from '../Context/Theme';


const ProjectCard = ({value}) => {
    const {dark} = useTheme()
  return (
    <Link className={`p-card ${dark && "darkbg"}`} to={value.Link}>
    <div className='image-cont'>
            <img className='image-cont-img' src={value.image} alt = "project display"/>
        </div>
        <div className={`text-side ${dark && "darkHead"}`}>
            <p className={`p-name ${dark && "darkHead"}`} >{value.maintitle}</p>
            <p className={`title ${dark && "darkHead"}`} >{value.catchPhrase}</p>
        </div>
        <div className={`line ${dark && "darkline"}`}></div>
        <p className= {`brief ${dark && "darkText"}`} >{value.brief}</p>
        </Link>
  )
}

export default ProjectCard

import React from 'react'
import '../../Styles/CaseStudy/caseStudies.css'
import Footer from '../../Resources/Footer'
import { useTheme } from '../../Context/Theme'
import OldFlow from '../../Assets/videos/old-flow.mp4'



// Images
import grapesCover from '../../Assets/images/grapes-cover.png'
import grapesHMW from '../../Assets/images/grapes-hmw.png'
import grapesAnalysis from '../../Assets/images/grapes-indepth-analysis.png'
import grapesMarketResearch from '../../Assets/images/grapes-market-research.png'
import grapesJourneyMap from '../../Assets/images/grapes-journey-map.png'
import grapesProblem from '../../Assets/images/grapes-problem.png'
import grapesProcess from '../../Assets/images/grapes-process.png'
import grapesPersona from '../../Assets/images/grapes-persona.png'
import grapesOverview from '../../Assets/images/grapes-overview.png'
import grapesCompetitiveAnalysis from '../../Assets/images/grapes-competitive-analysis.png'
import grapesIdeate from '../../Assets/images/grapes-ideate.png'
import grapesOpportunityApproach from '../../Assets/images/grapes-opportunity-approach.png'
import grapesLofi from '../../Assets/images/grapes-lofi.png'
import grapesLofi1 from '../../Assets/images/grapes-lofi-1.png'
import grapesLofi2 from '../../Assets/images/grapes-lofi-2.png'
import grapesPrototype from '../../Assets/images/grapes-prototype.png'
import grapesHifi from '../../Assets/images/grapes-hifi.png'
import grapesHifi1 from '../../Assets/images/grapes-hifi-1.png'
import grapesHifi2 from '../../Assets/images/grapes-hifi-2.png'
import grapesReflection from '../../Assets/images/grapes-reflection.png'


import grapesDiscovery from '../../Assets/images/grapes-discover.png'
import grapesDefine from '../../Assets/images/grapes-define.png'
import maildripDevelop from '../../Assets/images/maildrip-develop.png'
import maildripDeliver from '../../Assets/images/maildrip-deliver.png'
import maildripThanks from '../../Assets/images/maildrip-thanks.png'
import Navbar from '../../Resources/Navbar'

const Grapes = () => {
  const { dark } = useTheme()
    const id ="maildrip"
    

  return (

    <div className={`cs-bd ${dark && "darkbg"}`}>
        <section className='cardify-top'>
         <img src={grapesCover} alt="maildrip-cover" className='lofi'/>
            </section>

        {/*<section className='cardify'>
              <video controls className='lofi'>
            <source src={OldFlow} type="video/mp4"  />

            <source src={OldFlow} type="video/mp4" />

            or
            <a href={OldFlow}>MP4</a>
            video.
              </video>
         </section>*/}

         <section className='cardify'>
         <img src={grapesOverview} alt="maildripOverview" className='lofi'/>
        </section>  
        
        <section className='cardify'>
          <img src={grapesProcess} alt="maildripContent" className='lofi'/>
         </section>
         

        <section className='cardify'>
        <img src={grapesDiscovery} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
       <img src={grapesProblem} alt="maildripThanks" className='lofi'/>
      </section> 

      <section className='cardify'>
        <img src={grapesMarketResearch} alt="grapesMarketResearch" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesAnalysis} alt="grapesAnalysis" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesCompetitiveAnalysis} alt="grapesCompetitiveAnalysis" className='lofi'/>
       </section>

       <section className='cardify'>
        <img src={grapesJourneyMap} alt="grapesMarketResearch" className='lofi'/>
       </section>

       <section className='cardify'>
        <img src={grapesDefine} alt="maildripThanks" className='lofi'/>
       </section> 

        

       <section className='cardify'>
        <img src={grapesPersona} alt="grapesAnalysis" className='lofi'/>
       </section> 
       
       <section className='cardify'>
        <img src={grapesHMW} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesIdeate} alt="grapes-ideation" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesOpportunityApproach} alt="grapes-Opportunity-Approach" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesLofi} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesLofi1} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesLofi2} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesPrototype} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesHifi} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesHifi1} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesHifi2} alt="maildripThanks" className='lofi'/>
       </section> 

       <section className='cardify'>
        <img src={grapesReflection} alt="maildripThanks" className='lofi'/>
       </section> 



       <section className='cardify'>
        <img src={maildripThanks} alt="maildripThanks" className='lofi'/>
       </section> 
       
       





       <Footer />
      </div>
  )
}

export default Grapes

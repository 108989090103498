// import React from 'react'
// import useFetch from './useFetch';
// import { useParams } from 'react-router-dom';
// import { Link } from 'react-router-dom';
// import HTMLReactParser from 'html-react-parser';
// import { Helmet } from 'react-helmet';
// import { useTheme } from '../../Context/Theme'
// import '../../Styles/ThoughtStyles/ThoughtStyles.css'
// import Button from '../../Resources/Button'


// function Article() {
//   const { slug } = useParams(); // Use slug instead of id
//   const { data, loading, error, exists } = useFetch("all");

//   if (loading) {
//     return <h1>Loading...</h1>;
//   }

//   if (error) {
//     return <h1 style={{ textAlign: "center" }}>Something went wrong. Check your internet connection...</h1>;
//   }

//   if (!exists) {
//     return <h1>No article found.</h1>;
//   }

//   // Find the specific article by slug
//   const article = data.find((item) => item.slug === slug);

//   if (!article) {
//     return <h1>Article not found.</h1>;
//   }

//   return (
//     <div>
//       <div key={article.id}>
//         <div>
//           <h1 className='title'>{article.title}</h1>
//           <div className='post-info'>
//             <div className='author'>{article.author}</div>
//             <div className='date'>{article.date}</div>
//           </div>
//         </div>
//         <div className=''>{article.image}</div>
//         <div className='content'>{HTMLReactParser(article.content)}</div>
//         <div className='tags'>
//           {article.tags.split(",").map((tag) => (
//             <Link className='tag' style={{ textDecoration: "none" }} to={`/tags/${tag.trim()}`}>
//               <span>#{tag}</span>
//             </Link>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Article;

import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import HTMLReactParser from 'html-react-parser';

const Article = () => {
  const { title } = useParams(); // Get URL parameter
  const [singlePost, setSinglePost] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [profileLink, setProfileLink] = useState("");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  //const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@raymondmbam";
  const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@raymondmbam&t=${new Date().getTime()}"; // Add a timestamp to prevent caching


  useEffect(() => {
    Axios.get(mediumURL)
      .then((data) => {
        const avatar = data.data.feed.image;
        const profileLink = data.data.feed.link;
        const posts = data.data.items.filter(item => item.categories.length > 0);

        console.log("Filtered Posts:", posts);

        const formattedTitle = decodeURIComponent(title).trim();
        console.log("Formatted Title ID:", formattedTitle);

        const post = posts.find(item => {
          const formattedPostTitle = decodeURIComponent(item.title).trim();
          console.log("Article Title:", formattedPostTitle);
          return `/${formattedPostTitle}` === `/${formattedTitle}`;
        });

        if (post) {
          setSinglePost(post);
          setAvatar(avatar);
          setProfileLink(profileLink);
        } else {
          setError({ name: "Not Found", message: "The article was not found." });
        }
        setIsLoading(false);
      })
      .catch((e) => {
        setError({ name: e.message, message: e.toString() });
        setIsLoading(false);
      });
  }, [title]); // Dependency array with `title`

  if (isLoading) {
    return <h1>Loading...</h1>;
  }

  if (error) {
    const errorTitle = error.name;
    const errorMsg = error.message;
    return (
      <>
        <h2 className="red center1">{errorTitle}</h2>
        <p className="errorMessage center1">{errorMsg}</p>
      </>
    );
  }

  if (!singlePost) {
    return <h2>Article not found.</h2>;
  }

  // Function to customize rendering of HTML elements
  const parseOptions = {
    replace: (domNode) => {
      if (domNode.name === 'img') {
        return (
          <img
          className="thts-coverimg"
            src={domNode.attribs.src}
            alt={domNode.attribs.alt || 'Image'}
            style={{ maxWidth: '100%', height: 'auto' }} // Example styling for images
          />
        );
      }
      if (domNode.name === 'a') {
        return (
          <a
            href={domNode.attribs.href}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#525252', textDecoration: 'underline' }} // Example styling for links
          >
            {domNode.children[0].data}
          </a>
        );
      }
      if (domNode.name === 'p') {
        return (
          <p style={{ fontSize: '1.2rem', lineHeight: '2rem', color: '#525252', marginBottom: '1rem' }}>{domNode.children[0].data}</p>
        );
      }
      if (domNode.name === 'li') {
        return (
          <li style={{ fontSize: '1.2rem', lineHeight: '2rem', marginBottom: '1rem' }}>{domNode.children[0].data}</li>
        );
      }
      if (domNode.name === 'h3') {
        return (
          <h3 style={{marginBottom: '1rem' }}>{domNode.children[0].data}</h3>);
      }
      if (domNode.name === 'h4') {
        return (
          <h4 style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>{domNode.children[0].data}</h4>
          );
      }
      if (domNode.name === 'h5') {
        return (
          <h5 style={{marginBottom: '1rem' }}>{domNode.children[0].data}</h5>
          );
      }
      if (domNode.name === 'h6') {
        return (
          <h6 style={{ fontSize: '1.2rem', marginBottom: '1rem' }}>{domNode.children[0].data}</h6>
          );
      }
      if (domNode.name === 'blockquote') {
        return (
          <blockquote style={{ fontSize: '1.2rem', lineHeight: '1.4', marginBottom: '1rem' }}>{domNode.children[0].data}</blockquote>
        );
      }
      if (domNode.name === 'figcaption') {
        return (
          <figcaption style={{ textAlign: 'center', fontSize: '0.6rem', lineHeight: '1.4', marginBottom: '1rem' }}>{domNode.children[0].data}</figcaption>
        );
      }
    }
  };

  return (
    <div className='thts-pg'>
      <Helmet>
        <title>Thoughts | Raymond Mbam</title>
        <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
      </Helmet>

      <div className='thts-blog'>
        <p className="thts-Head">{singlePost.title}</p>
        <p className="thts-date">{moment(singlePost.pubDate).format('MMMM YYYY')}</p>
        {/* Parse and transform HTML content */}
        <div className='content'>
          {HTMLReactParser(singlePost.content, parseOptions)}
        </div>
      </div>
    </div>
  );
}

export default Article;



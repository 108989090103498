import React from 'react'
import { SelectData } from '../../Resources/SelectData'
import '../../Styles/ProjectStyles/PROJCSS.css'
import Card from '../../Resources/Card'
import { useTheme } from '../../Context/Theme'
import ProjectCard from '../../Resources/ProjectCard'

const ProjectList = () => {
  const {dark} = useTheme()
  return (
    <div className={`selected ${dark && "darkbg"}`}>

    <div  className='sel-card' >
        {SelectData.map((item, index) => {
            return(
                <div className='sel-card' key={index}>
                  <ProjectCard value={item}/>
                </div>
            )
        })}
    </div>
  </div>
  )
}

export default ProjectList



import React from 'react'
import Banner from '../Components/Homepage/Banner'
import "../Styles/HomeStyles/banner.css"
import Selected from '../Components/Homepage/Selected'
import Moreproject from '../Components/Homepage/Moreproject'
import Button from '../Resources/Button'
import { useTheme } from '../Context/Theme'
import { Helmet } from 'react-helmet';
import Footer from '../Resources/Footer'
import Navbar from '../Resources/Navbar'
import Hero from '../Resources/Hero'
import Values from '../Resources/Values'
import SelectWork from '../Components/Homepage/SelectWork'


const Home = () => {
  const {dark} = useTheme()
   return (
    <div className={`home ${dark && "darkbg"}`}>
      <Helmet>
      <title>Raymond Mbam | Product Designer</title>
      <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
    </Helmet>
    <Hero/>
    <SelectWork />
      <Footer />
    </div>
  )
}

export default Home

import React from 'react'
import CaseDefault from "../../Components/ProjectComponents/CaseDefault"
import CaseHead from "../../Components/ProjectComponents/CaseHead"
import SpecialHeader from '../../Components/ProjectComponents/SpecialHeader'
import { SelectData } from '../../Resources/SelectData'
import '../../Styles/CaseStudy/caseStudies.css'
import SubHeads from '../../Components/ProjectComponents/SubHeads'
import SubBody from '../../Components/ProjectComponents/SubBody'
import SingleImage from '../../Components/ProjectComponents/SingleImage'
import Contentblock from '../../Components/ProjectComponents/Contentblock'
import FigmaEmbed from '../../Components/ProjectComponents/FigmaEmbed'
import Navbar from '../../Resources/Navbar'
// Images

import collections from '../../Assets/images/alps-collections.png'
import pickuppalPVP from '../../Assets/images/pickuppalPVP.png'
import pickuppalIA from '../../Assets/images/pickuppalIA.png'
import pickuppalLOFI from '../../Assets/images/pickuppalLOFI.png'
import pickuppalHifi from '../../Assets/images/pickuppalHIFI.png'
import pickuppalTest1 from '../../Assets/images/pickuppalTest1.png'
import pickuppalTest2 from '../../Assets/images/pickuppalTest2.png'
import pickuppalTest3 from '../../Assets/images/pickuppalTest3.png'
import pickuppalGF from '../../Assets/images/pickuppalGF.png'
import { PiCursorClickFill } from "react-icons/pi";
import { FaNoteSticky } from "react-icons/fa6";
import { TbDirectionArrows } from "react-icons/tb";
import { PiChatsTeardropFill } from "react-icons/pi";
import { PiUsersFourFill } from "react-icons/pi";
import { FaHeart } from "react-icons/fa";
import { TiChartLine } from "react-icons/ti";
import { useTheme } from '../../Context/Theme'


const Pickuppal = () => {
    const { dark } = useTheme()
    const id ="pickuppal"
    const prod = SelectData.find((select) => id === select.id)

    const {image, catchPhrase} = prod

   

    const overview = [
        "<p className='pr-aag'>Addressing the challenge faced by young adults in their 20s who struggle to find local ad hoc team sports games, PickUpPal aims to enhance physical activity and social interaction.</p>",
        "<br/> Additionally, it aims to optimize the utilization of park facilities during off-peak hours, benefiting both players and facility owners.",
        "<br/> As a Product Designer, I played a role in creating a mobile app that connects players with available facilities, promoting spontaneous sports activities.",
      ]

      const Highlights = [{
        highHead:"Highlight ",
        highBody:[
            "An intuitive mobile app for finding and joining ad hoc team sports games.",
            "Leveraging location-based services for real-time information on game availability.",
            "Streamlined user flows for creating, joining, and managing games.",
            "Increased utilization of sports facilities during off-peak hours.",
        ]
      }]

      const BlockContent = [
        {
         icons: <PiCursorClickFill />,
         smallHead:"Adjusted Layout Elements",
         smallBody:"Reorganized profile and inbox icons to prevent mis-clicks; enhanced home page margins for improved visibility and scroll indication.",
        }]

      const BlockContent2 = [
          {
           icons: <FaNoteSticky />,
           smallHead:"Enhanced Usabiltity Test",
           smallBody:"Revised testing scripts for clearer task communication during testing sessions.",
          }]

      const BlockContent3 = [
            {
             icons: <TbDirectionArrows />,
             smallHead:"Improved User Navigation",
             smallBody:"Added go back home' button for streamlined navigation post-game initiation or joining.",
            }]

      const BlockContent4 = [
              {
               icons: <PiChatsTeardropFill />,
               smallHead:"Enriched Chat Details",
               smallBody:"Included game time, location, and member details for clearer chat differentiation and user verification.",
              }]
      
      const BlockContent5 = [
                {
                 icons: <TiChartLine />,
                 smallHead:"Valuable Business Insights",
                 smallBody:"Gained insights into balancing user satisfaction and business goals, creating a win-win scenario for both players and facility owners.",
                }]
      
      const BlockContent6 = [
                  {
                   icons: <FaHeart />,
                   smallHead:"Simplicity as a Strategy",
                   smallBody:"Embraced simplicity in design to reduce complexity and enhance user understanding, promoting an intuitive user journey.",
                  }]
                  
      const BlockContent7 = [
                    {
                     icons: <PiUsersFourFill />,
                     smallHead:"Enhanced Usabiltity Test",
                     smallBody:"Real-time moderated testing proved pivotal in refining the app, ensuring a user-friendly experience.",
                    }]

  return (
    <div className={`cs-body ${dark && "darkbg"}`}>
    <Navbar />
        <CaseDefault 
         Name="PickupPal"
         followUp={catchPhrase}
         biImage={image}
         role="Product Designer — Interaction Design, Visual Design, User Flows, Rapid Prototyping User Research, Prototyping"
         Team={["I collaborated with two Interaction designers and a Graphics design major."]}
         Timeline="Academic Project, Completed in 3 months"
         overview={overview}
         highlight={Highlights}
         />

         

        <CaseHead
        caseHeader={"THE PROBLEM"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Navigating the Disconnect: Local Sports Access and Facility Utilization. "} />

                    <p className="pr-aag">Young adults in their 20s who wish to engage in casual team sports often 
                    struggle to find like-minded buddies in their local communities.
                    </p>
                    
        </div>


        <CaseHead
        caseHeader={"CHALLENGE"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={'Understanding the problem from two perspectives'} />

                    <div className='one-columns'>

                    <p className="pr-aag">For each perspective, we identified their current situation, and the impact of that situation 
                    on their personal lives, and then found areas of opportunity.
                    </p>

                    <SubBody
                    subBody={[
                      '<p className="pr-aag"></p>',
                    
                    ]} />

                    <SpecialHeader
                    highHead={"User-Centered  —  Players 🏀"} />

                    <p className="pr-aag"><b>Undesirable situation:</b> Adults in their 20s have limited options for finding and joining ad 
                    hoc team sports games in their local communities, <i> <b>leading to missed opportunities for physical activity and social 
                    interaction.</b></i>
                    </p>
                    
                    <p className="pr-aag"><b>Opportunity Statement:</b> PickUpPal can provide a comprehensive platform for finding and joining 
                    ad hoc team sports games in local communities, aiming to <i><b>increase options for physical activity and social 
                    interaction </b></i> among adults in their 20s.
                    </p>
                    
                    <SpecialHeader
                    highHead={"Business Focused  —  Facilities 🛝"} />

                    <p className="pr-aag"><b>Undesirable situation:</b> Park facilities are <i><b>underutilized during off-peak hours, 
                    resulting in missed revenue opportunities </b></i>for facility owners and limited options for ad hoc team sports 
                    games for adults in their 20s.
                    </p>
                    
                    <p className="pr-aag"><b>Opportunity Statement:</b> PickUpPal can help to increase the utilization of sports facilities 
                    during off-peak hours by connecting adults in their 20s with available facilities and ad hoc team sports games, 
                    resulting in <i><b> increased revenue for facility owners</b></i> and <i><b>improved physical and mental 
                    health</b></i> outcomes for users.
                    </p>

                    <SpecialHeader
                    highHead={"How Might We❔🤔"} />

                    <p className="pr-aag"> How might we leverage <b>location-based services</b> and <b>data analytics</b> to 
                    provide users with real-time information about the availability of pick-up games for different sports 
                    in their local area?
                    </p>
        
                    </div>
        </div>

      
        <CaseHead
        caseHeader={"DESIGN"} />
        <div className='two-columns'>
                    <SubHeads
                    subCases={"Product Value Proposition"} />

                    <p className="pr-aag"> We developed a product value proposition to identify our users’ pain points, 
                    their gains, unique selling proposition and also capture some of the features we intend to design 
                    into the product.
                    </p>
        </div>
        <SingleImage single={pickuppalPVP} />

        <SpecialHeader
        highHead={"Information Architecture"} />
        <div className='two-columns'>

              <SingleImage single={pickuppalIA} />
              <p className="pr-aag"> The PickupPal app's information architecture for players is designed to facilitate 
              seamless connections between players and sports facilities for ad hoc team sports. 
              At the core, the architecture categorizes information into intuitive sections: player profiles, 
              available facilities, and scheduled games. </p>     
        </div>
        
        <SpecialHeader
        highHead={"Mid-Fi Prototype"} />
        <div className='two-columns'>

        <div className='one-columns'>
              <p className="pr-aag"><h4>Features To Test (Round I)</h4></p>
              <ul className="pr-aag">
                  <li className={dark && "darkText"}>Onboard (create profile): To observe if the onboarding process is frictionless</li>
                  <li className={dark && "darkText"}>Initiate a game: To check whether initiating a game is easy straightforward</li>
                  <li className={dark && "darkText"}>Join a game: To check whether joining a game is easy and straightforward.</li>
                </ul>
        </div>

          <SingleImage single={pickuppalLOFI} />
        </div>

        <SpecialHeader
        highHead={"Mid-Fi Prototype Testing"} />
        <div className='two-columns'>
              <div className='pk-imgt'>
                <SingleImage single={pickuppalTest1} />

                <p className="pr-aag">One extra screen was accidentally added in the onboarding flow, confusing the users; 
                scales were fixed on the “rate your tennis skills” screen, causing frustration</p>
                
              </div>

              <div className='pk-imgt'>
                <SingleImage single={pickuppalTest2} />

                <p className="pr-aag"> Testing prompt very subtly implies users should check the profile of a game initiator, 
                a few users did not make the connection between this task and “Trevor’s profile”.</p>

                </div>

              <div className='pk-imgt'>
                <SingleImage single={pickuppalTest3} />
                
                <p className="pr-aag"> We set the success criteria for this task to be landing on the messaging screen, however, 
                most users went back to the home page after joining the game. Landing on the home page should also be a success 
                criteria for this task.</p>
              </div>
        </div>

        <SpecialHeader
        highHead={"General User Feedback"} />
        <div className='two-columns'>
              <SingleImage single={pickuppalGF} />

              <div className='one-columns'>
                <p className="pr-aag"><h4>Iterations</h4></p>
                <ul className={dark && "darkText"}>
                  <li className={dark && "darkText"}>Images and branding We replaced placeholder images with stock images to render a more realistic look.</li>
                  <li className={dark && "darkText"}>Adding an in-app messaging feature: The messaging feature is one that we did not have time to test in 
                  the previous round, but very necessary for the App users</li>
                  <li className={dark && "darkText"}>Update testing scripts: Updated the subtle questions to be more clear in communicating usability testing tasks.</li>
                </ul>

              </div>
              
        </div>

        <SpecialHeader
        highHead={"High-FI Prototype"} />
        <SingleImage single={pickuppalHifi} />

        


        <CaseHead
        caseHeader={"ITERATIONS & RESULTS"} />
        <div className='two-columns'>
        <div className='remv'>
          <FigmaEmbed 
              url="https://www.figma.com/proto/qmwLUIlm19XtR78EakfIp1/PickUpPal?embed_host=notion&kind=proto&node-id=398-2007&page-id=0%3A1&scaling=scale-down&show-proto-sidebar=1&starting-point-node-id=101%3A2&t=qXD41NmoXQ4xC5aw-1&type=design&viewport=886%2C-251%2C0.1"
                width="100%"
                height="600"
                />
        </div>
         


           <div className='one-columns'>
              <div className='two-columns'>
                <Contentblock
                  BlockContent = {BlockContent}
                  Column= {"column"}
                  HeaderColor= "FF0000"
                  />

                <Contentblock
                  BlockContent = {BlockContent2} />
                  </div>

              <div className='two-columns'>
                  <Contentblock
                    BlockContent = {BlockContent3}
                    Column= {"column"}
                    HeaderColor= "FF0000"
                    />
  
                  <Contentblock
                    BlockContent = {BlockContent4} />
                    </div>
                
              
              </div>
        </div>


        <CaseHead
        caseHeader={"RETROSPECTIVES"} />
        <div className='two-columns'>
                  <SubHeads
                  subCases={"Product Value Proposition"} />

                    <Contentblock
                    BlockContent = {BlockContent5}
                    Column= {"column"}
                    HeaderColor= "FF0000"
                    />

                    <Contentblock
                    BlockContent = {BlockContent6} />

                    <Contentblock
                    BlockContent = {BlockContent7} />

        
        </div>
        
       
      
    </div>
  )
}

export default Pickuppal

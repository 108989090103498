import React from 'react'
import CaseDefault from "../../Components/ProjectComponents/CaseDefault"
import CaseHead from "../../Components/ProjectComponents/CaseHead"
import SpecialHeader from '../../Components/ProjectComponents/SpecialHeader'
import { SelectData } from '../../Resources/SelectData'
import '../../Styles/CaseStudy/caseStudies.css'
import SubHeads from '../../Components/ProjectComponents/SubHeads'
import SubBody from '../../Components/ProjectComponents/SubBody'
import SingleImage from '../../Components/ProjectComponents/SingleImage'
import mdpersona from '../../Assets/images/md-persona.png'
// Images
import alpsCollections from '../../Assets/images/alps-collections.png'
import alpsDesignsystem from '../../Assets/images/alps-designSystem.png'
import alpsGuides from '../../Assets/images/alps-guides.png'
import alpsReset from '../../Assets/images/alps-reset.png'
import alpsVerify from '../../Assets/images/alps-verify.png'
import alpsBrand from '../../Assets/images/alps-brand.png'
import { useTheme } from '../../Context/Theme'
import Navbar from '../../Resources/Navbar'

const Alps = () => {
    const { dark } = useTheme()
    const id ="alps"
    const prod = SelectData.find((select) => id === select.id)

    const {image, catchPhrase, role} = prod

    const overview = [
        "In my role as a Founding Designer at Alps, I led the design efforts to release Collections & Guides, Alps’ first core product, designed to empower product teams to create help centers.",
      ]

      const Highlights = [{
        highHead:"Highlight ",
        highBody:[
            "An intuitive mobile app for finding and joining ad hoc team sports games.",
            "Leveraging location-based services for real-time information on game availability.",
            "Streamlined user flows for creating, joining, and managing games.",
            "Increased utilization of sports facilities during off-peak hours.",
        ]
      }]

  return (
    <div className={`cs-body ${dark && "darkbg"}`}>
        <CaseDefault 
         Name="Alps"
         followUp={catchPhrase}
         biImage={image}
         role="Founding Designer & Product Lead — Interaction Design, Visual Design, User Flows, Feature Prioritization"
         Team={["Collaborating with 1 Product Manager and 2 Software Developers"]}
         Timeline="Completed in MVP in 4 months"
         overview={overview}
         />

         
        <CaseHead
        caseHeader={"CONTEXT"} />

            <div className='two-columns'>
                    <SubHeads
                    subCases={"Empowering Growth with Simple Tools."} />

                    <div className='one-columns'>

                    <p className="pr-aag"> During my tenure as a designer at MailDrip, we hosted the product’s help center on Notion. 
                    This is the foundation of a lean development approach: to focus on the core business and avoid unnecessary tool development. 
                    </p>  

                    <p className='pr-aag'>As a systems thinker and professional designer, I naturally gravitate toward identifying new 
                    challenges and finding effective solutions. 
                    </p>

                    <p className='pr-aag'>One significant challenge I observed in growing development teams specifically in the African 
                    market is the need for tools that aren’t core to the business but are important to its success. To address this, my 
                    team and I prioritized developing a user-friendly platform for product teams to create support content for their customers.
                    </p>
                    </div>

                
            </div>

        <CaseHead
        caseHeader={"DESIGN SYSTEM"} />
                <div className='two-columns'>
                <SubHeads
                subCases={"Minimalist Design Approach for Consistent Branding."} />

                <div className='one-columns'>

                <p className='pr-aag'>In designing the client’s interface, I chose a minimalist design approach to ensure that the 
                visual elements are clean and uncluttered. This strategy emphasizes the power and reliability of the tool, reinforcing 
                our commitment to supporting product teams. </p>
                
                <p className='pr-aag'>Once the components were finalized, they were handed over to our front-end developer to be 
                    transformed into reusable components. This process streamlines development and maintains uniformity across various 
                    aspects of the product.</p>

                </div>

                
        </div>
        <SingleImage single={alpsDesignsystem} />

        
        <CaseHead
        caseHeader={"ARCHITECTURE"} />
                <div className='two-columns'>
                   <SubHeads
                    subCases={"Collections"} />

                    <div className='one-columns'>

                    <p className='pr-aag'>Collections serve as categories for articles, allowing users to easily organize and 
                    access related content. This structure enhances the help center's navigability, making it simple for users 
                    to find the information they need quickly. </p>

                    </div>

                        
                    </div>
            <SingleImage single={alpsCollections} />

                    <div className='two-columns'>
                    <SubHeads
                    subCases={"Guides"} />

                    <div className='one-columns'>

                    <p className='pr-aag'>Recognizing the cluttered nature of YouTube playlists for hosting support videos, 
                    Guides offers a streamlined solution for organizing video content. This feature ensures that support 
                    videos are presented in a clear and orderly manner, enhancing the user experience and accessibility of 
                    valuable resources. </p>
                    
                    </div>
            </div>
        <SingleImage single={alpsGuides} />

                    <div className='two-columns'>
                         <SubHeads
                          subCases={"Sign Up — Workspace Name."} />

                    <div className='one-columns'>

                    <p className='pr-aag'>To enhance user engagement and investment, I leveraged the Endowment Effect during the 
                    signup process by allowing users to customize their workspace name in real time. As users type their company 
                    name, it dynamically updates to 'companyname.tryalps.app,' giving them an immediate sense of ownership and 
                    personalization. </p>
                    
                    </div>
                        </div>
        <SingleImage single={alpsBrand} />

                <div className='two-columns'>
                            <SubHeads
                            subCases={"Sign Up — Deferred Email Verification"} />

                    <div className='one-columns'>
                    <p className='pr-aag'>To increase our signup rate, we implemented a strategy of deferred email verification. 
                    By waiting until users have experienced the product's value before asking them to confirm their email, we reduce 
                    friction during the initial signup process.</p>

                    <p className='pr-aag'><b>We tested the 'Sniper Link' technique:</b></p>

                    <p className='pr-aag'>Sending users to their inbox during onboarding can be distracting and risky, leading 
                    to drop-offs. To minimize this, I employ 'Sniper Links', which use URL parameters to pre-fill the search query. 
                    For example, if users sign up with a @gmail.com address, the verification button directs them to a pre-filtered 
                    Gmail search for 'Alps' <b>(e.g., https://mail.google.com/mail/u/0/#search/Alps)</b>. This technique ensures that
                    users can quickly and easily find the verification email, reducing the chance of losing them during the onboarding process.</p>

                    </div>

                           
                    </div>
        <SingleImage single={alpsVerify} />
        <div className='two-columns'>
        <SubHeads
        subCases={"Authentication — Reset Password"} />

        <div className='one-columns'>
        <p className='pr-aag'>To streamline the password recovery process, I implemented a process where users requesting a password 
        reset receive a one-time login link. This link provides immediate access to their account with an option to reset their 
        password.
        </p>

        <p className='pr-aag'><b>It's a bold move:</b></p>
        <p className='pr-aag'>This approach is a bold move from a security standpoint, but it allows us to test the idea of reducing 
        friction during password recovery. By granting temporary access without requiring immediate password reset, we aim to enhance 
        user experience and improve retention during the critical recovery phase.</p>
        </div>

    
</div>
        <SingleImage single={alpsReset} />

       

      
    </div>
  )
}

export default Alps

import React, { useState } from 'react'
import "../../Styles/ProjectCases/case01.css"

const SubBody = ({subBody}) => {
  console.log(subBody)



  return (
      <div className='subBody'>
      {subBody.map((item, index) =>(
          <div className='pr-aag'  key={index} dangerouslySetInnerHTML={{ __html: item }}></div>

        ))
      }
    </div>
  )
}

export default SubBody

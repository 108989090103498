import React from 'react'

const Buff = () => {
  return (
    <div>
      
    </div>
  )
}

export default Buff

import React from 'react'
import Hero from '../Resources/Hero'
import ProjectList from '../Components/ProjectPage/ProjectList'
import Navbar from '../Resources/Navbar'
import { useTheme } from '../Context/Theme'

const Project = () => {
  const {dark} = useTheme()
  return (
    <div>
        <section className={`hero-section ${dark && "darkbg"}`}>
          <div className="hero-content">
            <h1 className={`hero-title ${dark && "darkText"}`}>Work.</h1>
          </div>
          <div className={`line ${dark && "darkline"}`}></div>
        </section>
        <ProjectList />
    </div>
  )
}

export default Project

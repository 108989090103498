import React from 'react'
import { Link } from 'react-router-dom'
import {GoArrowUpRight} from 'react-icons/go'
import "../../Styles/AboutStyles/skill.css"
import { useTheme } from '../../Context/Theme'


const Skillsset = () => {

    const { dark } = useTheme()
    const tools = [ "Figma", "FigJam", "Adobe Illustrator", "Maze", "Miro"]

    const skills = ["ReactJS", "HTML/CSS", "Wireframing & Prototyping", "User Testing", "Design Sprint" ]

    const certification =[
        {
            name:"Programming with JavaScript (Meta)",
            link:"https://www.coursera.org/account/accomplishments/records/9677K5VQWBYR"

        },

        {
            name:"Introduction Front-End Development (Meta)",
            link:"https://www.coursera.org/account/accomplishments/certificate/YL7QK2Z93J4U"

        },

        {
            name:"Design System for Designers (Gymnasium)",
            link:"https://www.credential.net/2b74d2dc-9207-4e10-9100-4d525b6c4368"

        },

        {
            name:"Agile Product Management (Utiva)",
            link:"/"

        },


    ]


  return (
    <div className="skill-edu">

    <div className= 'back-bg'>

   
    {/**First one */}
    <div className={`skl-box ${dark && "darkbox"}`}>
        <div >
            <ul className="smc">
                <p className="smc-header">Software</p>
                {tools.map((tool, index) =>(
                    <li className="smc-items" key={index}>{tool}</li>
                ))}
                
            </ul>
        </div>

        {/**Second */}
        <div>
            <ul className="smc">
                <p className="smc-header">Methods & Languages</p>
                {skills.map((skill, index) =>(
                    <li className="smc-items" key={index}>{skill}</li>
                ))}
            
            </ul>
        
        </div>

        <div>
            <ul className="smc">
                <p className="smc-header">Certifications</p>
                {certification.map((certificate, index) =>(
                    <li className="smc-items" key={index}>
                        <Link to={certificate.link}>
                        {certificate.name} 
                        <GoArrowUpRight />
                        </Link>
                    </li>
                ))}
            
            </ul>
    
        </div>
    </div>
    </div>
    </div>
  )
}

export default Skillsset

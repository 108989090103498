import React from 'react'
import star from "../../Assets/images/star.svg"
import "../../Styles/ProjectCases/special.css"
const SpecialHeader = ({highHead}) => {
  return (
    <div className='special'>
        <img src={star}/>

      <p className='high'>{highHead}</p>

        <img src={star}/>
      
    </div>
  )
}

export default SpecialHeader

import React from 'react';
import './Hero.css';
import { useTheme } from '../Context/Theme';

function HeroSection() {
  const {dark} = useTheme()
    return (
        <section className={`hero-section ${dark && "darkbg"}`}>
          <div className="hero-content">
            <h1 className={`hero-title ${dark && "darkText"}`}>Raymond Mbam</h1>
            <p className="hero-intro">
              I’m a product designer who loves creating 0 — 1 digital products. 
              My roles have spanned product strategy and design, allowing me to 
              steer product visions and craft interfaces, but hey, I find time 
              to contribute to engineering, marketing, and even operations.
              <br/>
              <br/>
              I’m currently learning React.js and JavaScript.
            </p>
            <div className={`line ${dark && "darkline"}`}></div>
          </div>
        </section>
      );
}

export default HeroSection;

import React from 'react'
import "../../Styles/ProjectCases/casedefault.css"
import SpecialHeader from './SpecialHeader'
import { useTheme } from '../../Context/Theme'

const CaseDefault = ({Name, followUp, role, Team, Timeline, overview, biImage, highlight }) => {
    const { dark } = useTheme()
  return (


    <div className={`casedefault ${dark && 'darkbg'}`}>
        <div className='bigBanner'>
            <img src={biImage} alt="bigimage" className='bigImage'/>
        </div>

        <div className='nameStyle'>
         <h1 className={dark && "darkHead"}>{Name}</h1>
        <p className={dark && "darkText"}>{followUp}</p>
        </div>

        <div className='dif-info'>
           
            <div className='def2'>
                {/**rolesection */}
                <div>
                    <h3 className={dark && "darkHead"}>My Role</h3>
                    <p className={dark && "darkText"}>{role}</p>
                </div>

                <div>
                    <h3 className={dark && "darkHead"}>Team</h3>
                    <p className={dark && "darkText"} dangerouslySetInnerHTML={{ __html: Team }}></p>
                </div>

                <div>
                    <h3 className={dark && "darkHead"}>Timeline & Status</h3>
                    <p className={dark && "darkText"}>{Timeline}</p>
                </div>
            </div>

            <div className='def2'>
            <div>
                <h3 className={dark && "darkHead"}>Overview</h3>
                {overview.map((item, index) =>(
                    <p className={dark && "darkText"} key={index} dangerouslySetInnerHTML={{ __html: item }}></p>
                ))}
            </div>

            <div>
                {highlight && highlight.map((high, index)  =>(
                    <div key={index}>
                    <SpecialHeader highHead ={high.highHead}/>

                    <div>
                    {high.highBody.map((item, index) =>(
                        <li key={index}  dangerouslySetInnerHTML={{ __html: item }} className={dark && "darkText"}></li>
                    ))}
                    
                    </div>
                    </div>
                ))}
            </div>

            
          
            </div>
        </div>
      
    </div>
  )
}

export default CaseDefault


import React from 'react';

const FigmaEmbed = ({ url, width, height }) => {
  return (
    <iframe
      title="Figma Prototype"
      src={url}
      width={width}
      height={height}
      allowFullScreen
      frameBorder="0"
    ></iframe>
  );
};

export default FigmaEmbed;
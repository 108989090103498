import React from 'react'
import sunshine from "../Assets/logos/sunbold.svg"
import "../Styles/AboutStyles/About.css"
import Story from '../Components/AboutPage/Story'
import Workhistory from '../Components/AboutPage/Workhistory'
import Skillsset from '../Components/AboutPage/Skillsset'
import raymondmbamd from "../Assets/images/raymondmbamd.jpg"
import { useTheme } from '../Context/Theme'
import { IoSunny } from "react-icons/io5";
import { IconContext } from 'react-icons'
import { Helmet } from 'react-helmet';
import Navbar from '../Resources/Navbar'


const About = () => {

  const { dark } = useTheme()
  return (
    <div className={`thoughts-pg ${dark && "darkbg" }`}>
     <Helmet>
     <title>About Me | Raymond Mbam</title>
     <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
   </Helmet>
        <div className={`a-banner ${dark && "darkbg" }`}>
          <p className={dark && "darkText"}>
              <span>Ray</span>
{/**  <img src={sunshine} alt="shine" className='shine'/>*/}         
              {dark ? 
                <IconContext.Provider value={{color:"#FFFFFF"}}>
                  <IoSunny className='shine'/>
                </IconContext.Provider> :

                <IconContext.Provider value={{color:"black"}}>
                  <IoSunny className='shine' />
                </IconContext.Provider> 

              }
              <span>mond</span>
          </p>
            <div className='cir'>
                <img src={raymondmbamd} className='cir-image'/>
            </div>

          </div>

        <div className='box-ed'>
            <Story/> 
            <Skillsset />
        </div>
    </div>
  )
}

export default About

import React from 'react'
import sunshine from '../Assets/logos/sunbold.svg'
import "../Styles/seebuild.css"
import { Link } from 'react-router-dom'
import { GoArrowUpRight } from 'react-icons/go'
import alps from "../Assets/images/pickuppalHeader.png"
import { IconContext } from 'react-icons'
import Button from "../Resources/Button"
import { useNavigate } from 'react-router-dom'
import { useTheme } from '../Context/Theme'
import { IoSunny } from "react-icons/io5";
import { Helmet } from 'react-helmet';

const SeeBuild = () => {
    const navigate = useNavigate()
    const { dark } = useTheme()

  return (
    <div>
        <div className= {`b-banner ${dark && "darkbg"}`}>
                <Helmet>
            <title>Ideas | Raymond Mbam</title>
            <meta name="description" content="Raymond Mbam - Product Designer & Strategist" />
        </Helmet>
            <div className="sides" >
            {dark ? 
                <IconContext.Provider value={{color:"#FFFFFF", size:"60px"}}>
                  <IoSunny className='shine'/>
                </IconContext.Provider> :
      
                <IconContext.Provider value={{color:"black", size:"60px"}}>
                  <IoSunny className='shine' />
                </IconContext.Provider> 
      
              }        
                <p>Ideas</p>  
            </div>
            {/** <Button name= "Fund " action={"mailto:raymondmbamdaiel@gmail.com"} /> **/}

        </div>

        <div className={`seeBuild ${dark && "darkbg"}`}>
            <div className={`btext-spc ${dark && "darkText"}`}>
                <p className={`bspc-hd ${dark && "darkHead"}`}>Alps — Empowering companies to craft helpful guides</p>

                <p className={`bspc-tx ${dark && "darkbg"}`}>Designed a local game discovery app to address the challenge faced by young adults in their 20s who struggle to find local ad hoc team sports games. PickUpPal aimed to enhance physical activity and social interaction.
                </p>
                <IconContext.Provider value={{color:"black", size:"20px"}} >
                    <ul>
                        <p className='tiem'>Team:</p>
                        <li>
                            <Link to="/">
                            Doowuese Igabzua <span className='ligh'>(PM) </span>
                            <GoArrowUpRight />
                            </Link>
                        </li>
                        <li>
                            <Link to="/">Folayimi Ridwan <span className='ligh'>(SWE)</span></Link>
                        </li>
                        <li>
                            <Link to ="/">Collins Thomas <span className='ligh'>(SWE)</span> </Link>
                        </li>
                    </ul>

                    <Link to ="" 
                    className={`tryalps ${dark && "darkButton"}`}
                    onClick={(e) => {
                    e.preventDefault();
                    window.open("https://tryalps.com/collections", "_blank", "noopener,noreferrer");
        }}>
                        tryalps.com
                        <GoArrowUpRight />

                    </Link>
                </IconContext.Provider>
            </div>

            <div className='bspc-img'>
                <img src={alps} alt="" className='alps'/>
            </div>

        </div>
      
    </div>
  )
}

export default SeeBuild

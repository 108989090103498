// import React from 'react'
// import { useState } from 'react'
// import "../../Styles/ProjectCases/single.css"

// const SingleImage = ({single}) => {

//   const [isExpanded, setIsExpanded] = useState(false);

//   const handleImageClick = () => {
//     setIsExpanded(!isExpanded);
//   };

//   return (
//     <div className={`image-container ${isExpanded ? 'expanded' : ''}`} onClick={handleImageClick}>
//   {/*<div className='singleimage'>*/}
//       <img src={single}className='expandable-image' alt='single'/>
//     </div>
//   )
// }

// export default SingleImage


import React, { useState } from 'react';
import ImageModal from './ImageModal';
import "../../Styles/ProjectCases/single.css"

const SingleImage = ({ single }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleImageClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="singleimage-container">
      <img src={single} className="singlesize-expandable" onClick={handleImageClick} />
      {isModalOpen && <ImageModal src={single} onClose={handleCloseModal} />}
    </div>
  );
};

export default SingleImage;

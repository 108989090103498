import React from 'react';
import "../../Styles/ProjectCases/single.css"

const ImageModal = ({ src, onClose }) => {
  const handleModalClick = (e) => {
    // Prevent the event from propagating to the modal content
    e.stopPropagation();
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={handleModalClick}>
        <img src={src} className="modal-image" alt="Expanded view" />
      </div>
    </div>
  );
};

export default ImageModal;

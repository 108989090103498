import React, { useState } from 'react'
import "../../Styles/AboutStyles/story.css"
import { useTheme } from '../../Context/Theme'

const Story = () => {

    const { dark } = useTheme()
    return (
        <div className='story-frame'>
            <div className='strys'>
                <h3 className={`lineHead ${dark && "darkHead"}`}>My Product Design Journey</h3>
                <div className='st-ry'>
                    <p className={` prag ${dark && "darkText"}`}>My journey into product design began back in late 2019, just before the chaos of COVID hit, when I ventured into building an event-ticketing platform in Nigeria. </p>
                    <p className={` prag ${dark && "darkText"}`}>I got my first role as a product designer at Charisol (2021) where I had the incredible opportunity to contribute to projects like Maildrip and Aluuka, learning to craft joyful user experience.</p>
                </div>
            </div>

            <div className='strys'>
            <h3 className={`lineHead ${dark && "darkHead"}`}>My process</h3>
            <div className='st-ry'>
                <p className={` prag ${dark && "darkText"}`}>Design is not just a career for me, it's a lifestyle. Guided by empathy and driven by inclusivity, I approach each project with a set of principles that prioritize the user experience above all else. </p>

                <ul >
                    <li className={dark && "darkText"}>I question whether people are truly impacted by the issues  I'm solving for. </li>
                    <li className={dark && "darkText"}>Then, I assess it’s financially viable and technically feasible. Can we afford to tackle this challenge now? Do we possess right team and tool bring our vision to life?</li>
                    <li className={dark && "darkText"}>And perhaps most importantly, I ask, "Why now?" Why does it makes sense to build now?</li>
                </ul>

                <p className={` prag ${dark && "darkText"}`}>I have a Master's degree in Interaction Design from George Washington University, and honestly it was from there I started to see design an experience and a as a strategic business decision.</p>

                <p className={` prag ${dark && "darkText"}`}>Over the years, I’ve had the opportunity to design experiences across multiple domains including finance, healthcare, marketing, and most recently, with Alps—a SaaS tool empowering startups in Africa create support content for their customers.</p>

                <p className={` prag ${dark && "darkText"}`}>I love driving projects from conception to market, and improve features within a user-centered design process.</p>
               
            </div>
        </div>
        
        </div>
    )
  }
  
  export default Story
  
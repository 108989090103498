import React from 'react'
import '../App.css'
import { GoArrowUpRight } from "react-icons/go";


const Button = ({action, color, name, white, icon}) => {
  return (
    <div>
    <a href={action} className="button-link">
      <button className={white ? "global-button white" : "global-button "}>
        {name}
        {icon ? <GoArrowUpRight /> : null}
      </button>
      </a>
    </div>
  
  )
}

export default Button
